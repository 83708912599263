<template>
  <AppBar>
    <!-- 按钮 -->
    <div class="nav">
      <div class="nav-left">
        <el-button
          @click="reverl(0)"
          type="info"
          plain
          round
          size="medium"
          :class="active0"
          >全部</el-button
        >
        <el-button
          :class="active1"
          @click="reverl(1)"
          type="info"
          plain
          round
          size="medium"
          ><i class="el-icon-chat-square icon-r"></i>任务提醒</el-button
        >
        <el-button
          :class="active2"
          @click="reverl(2)"
          type="info"
          plain
          round
          size="medium"
          ><i class="el-icon-bell icon-r"></i>状态提醒</el-button
        >
        <el-button
          :class="active3"
          @click="reverl(3)"
          type="info"
          plain
          round
          size="medium"
          ><i class="el-icon-chat-line-square icon-r"></i>系统公告</el-button
        >
      </div>
      <div class="nav-right">
        <el-button type="text" :class="not" @click="notclick(1)"
          >未读取</el-button
        >
        <el-button type="text" :class="already" @click="notclick(2)"
          >已读取</el-button
        >
      </div>
    </div>
    <!-- 内容 -->
    <div class="wrap">
      <el-button class="wrap-btn" type="text" @click="open" v-if="false1">
        <i class="el-icon-edit-outline"></i> 全部已读</el-button
      >
      <ul>
        <li
          v-for="(item, index) in list"
          :class="coo"
          :style="item.is_read | getColor"
        >
          <div class="list-left">
            {{ item.title
            }}<span
              v-if="item.msg_type_id !== 3"
              href="#"
              @click="a_click(item.project_id, item.msg_id, item.project_state)"
              >点击</span
            >
            <span
              v-if="item.msg_type_id == 3"
              href="#"
              @click="a_click1()"
              >点击</span
            >
          </div>
          <div class="list-right">
            <span v-if="item.msg_type_id !== 3">{{
              item.create_time | formatDate
            }}</span>
          </div>
        </li>
      </ul>
    </div>
    <div class="page">
      <el-pagination
        :hide-on-single-page="false"
        layout="total, prev, pager, next"
        @current-change="handleCurrentChange"
        :current-page.sync="currentPage"
        style="margin-top: 20px"
        background
        :page-count="total"
        :page-size="20"
        :total="z_page"
      >
      </el-pagination>
    </div>
  </AppBar>
</template>
<script>
import AppBar from "../../components/AppBar";

export default {
  data() {
    return {
      false1: false,
      coo: "co",
      not: "colorgay",
      already: "colorgay",
      active0: "active",
      active1: "",
      active2: "",
      active3: "",
      currentPage: 1,
      title: "",
      list: [],
      total: 0,
      z_page: 0,
      xmid: "",
      page1: 1,
      typeid: 0,
      isread: 0,
    };
  },
  created() {
    this.$common.getInterface(
      "/api/v1.notice/getMsgList",
      {
        page: 1,
        type_id: 0,
      },
      this,
      (res) => {
        console.log(res);
        // var is_read1 = res.data.data.map(function (item) {
        //   return item["is_read"];
        //   // if (item["is_read"] == 1) {
        //   //   return item["project_id"];
        //   // }
        // });

        // console.log(is_read1);
        console.log(res.data.data);
        this.list = res.data.data;
        this.total = res.data.last_page;
        this.z_page = res.data.total;
      }
    );
  },
  filters: {
    formatDate: function (value) {
      let date = new Date(value * 1000);
      let y = date.getFullYear();
      let MM = date.getMonth() + 1;
      MM = MM < 10 ? "0" + MM : MM;
      let d = date.getDate();
      d = d < 10 ? "0" + d : d;
      let h = date.getHours();
      h = h < 10 ? "0" + h : h;
      let m = date.getMinutes();
      m = m < 10 ? "0" + m : m;
      let s = date.getSeconds();
      s = s < 10 ? "0" + s : s;
      return y + "-" + MM + "-" + d + " " + h + ":" + m + ":" + s;
    },
    getColor(value) {
      console.log(value);
      if (value == 1) {
        return "color:#ccc";
      } else {
        return "color:#000";
      }
    },
  },
  methods: {
    open() {
      this.$confirm("将所有消息标为已读?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          setmas("标记成功!", "success", this);
          this.$common.postInterface(
            "/api/v1.notice/readAll",
            {},
            this,
            (res) => {
              console.log(res);
              this.$router.go(0);
            }
          );
        })
        .catch(() => {
          setmas("已取消", "success", this);
        });
    },
    handleCurrentChange(e) {
      console.log(e);

      this.$common.getInterface(
        "/api/v1.notice/getMsgList",
        {
          page: e,
          type_id: this.typeid,
        },
        this,
        (res) => {
          console.log(res);
          console.log(res);
          // var is_read1 = res.data.data.map(function (item) {
          //   return item["is_read"];
          // });

          // console.log(is_read1);
          console.log(res.data.data);
          this.list = res.data.data;
          this.total = res.data.last_page;
          this.z_page = res.data.total;
          console.log(this.z_page);
        }
      );
    },
    reverl(id) {
      this.false1 = false;
      if (id == "1" || id == "2" || id == "3") {
        this.active0 = "activef";
      }

      this.already = "colorgay";
      this.not = "colorgay";
      // this.$common.getInterface(
      //   "/api/v1.notice/getMsgList",
      //   {
      //     page: 1,
      //     type_id: id,
      //   },
      //   this,
      //   (res) => {
      //     this.list = res.data.data;
      //     this.total = res.data.last_page;
      //     this.z_page = res.data.total;
      //   }
      // );
      this.typeid = id;
      this.$common.getInterface(
        "/api/v1.notice/getMsgList",
        {
          page: this.page1,
          type_id: this.typeid,
        },
        this,
        (res) => {
          // var yj = res.data.data
          // var is_read1 = res.data.data.map(function (item) {
          //   return item["is_read"];
          // });
          // console.log(is_read1);
          console.log(res.data.data);
          this.list = res.data.data;
          this.total = res.data.last_page;
          this.z_page = res.data.total;
        }
      );
    },
    a_click1(){
      this.$router.push({ path: `/Problem/` });
    },
    a_click(id, msgid, prostate) {
      console.log(1111111);
      //    this.$router.push({ path: `/audit/:id` });
      this.$common.postInterface("/api/v1.Notice/read", { id: msgid });
      if (prostate == 2) {
        this.$router.push({ path: `/review/` });
      } else if (prostate == 3) {
        this.$router.push({ path: `/create/` + "?id=" + id });
      } else if (prostate == 4 || prostate == 5) {
        this.$router.push({ path: `/audit/` + id });
      }
    },
    notclick(pd) {
      // this.$common.getInterface(
      //   "/api/v1.notice/getMsgList",
      //   {
      //     page: 1,
      //     type_id: 0,
      //     is_read: pd,
      //   },
      //   this,
      //   (res) => {
      //     console.log(res);
      //     this.list = res.data.data;
      //     this.total = res.data.last_page;
      //     this.z_page = res.data.total;
      //   }
      // );
      this.isread = pd;
      this.$common.getInterface(
        "/api/v1.notice/getMsgList",
        {
          page: this.page1,
          type_id: this.typeid,
          is_read: this.isread,
        },
        this,
        (res) => {
          console.log(res);
          this.list = res.data.data;
          this.total = res.data.last_page;
          this.z_page = res.data.total;
        }
      );
      if (pd == 1) {
        this.not = "colorskyblues";
        this.already = "colorgay";
        this.false1 = true;
      } else if (pd == 2) {
        this.not = "colorgay";
        this.already = "colorskyblues";
        this.false1 = false;
      }
    },
  },
  components: {
    AppBar,
  },
};
// 提醒弹出框
function setmas(msg, type, vm) {
  vm.$message({
    message: msg,
    type: type,
    customClass: "popup",
  });
}
</script>
<style>
.popup {
  width: 400px;
  height: 70px;
  font-size: 26px;
  font-weight: 700;
  position: absolute !important;
  top: 45% !important;
  margin-top: -35px !important;
}
</style>

<style lang="less" scoped>
@deep: ~">>>";
@{deep}.wrap {
  box-sizing: border-box;
  width: 100%;
  // height: 80%;
  padding: 20px;
  border-radius: 20px 20px 0 0;
  background-color: white;
  overflow: hidden;
  position: relative;
}
.wrap-btn {
  position: absolute;
  right: 90px;
  top: 0;
}
.demonstration {
  margin-top: 20px;
}
.page {
  display: flex;
  align-items: center;
  // align-content: center;
}
.list-left span {
  text-decoration: none;
  color: #409eff;
  padding-left: 10px;
  cursor: pointer;
}
.icon-p {
  padding-left: 40px;
  padding-right: 40px;
}
.active {
  background: #909399;
  color: #fff;
  padding: 11px 40px;
}
.activef {
  background: #f4f4f5;
  color: #909399;
  padding: 11px 40px;
}
.icon-r {
  margin-right: 8px;
  color: #409eff;
}
.colorgay {
  color: #606266;
}
.colorskyblue {
  color: #409eff;
}
.nav {
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
}
.wrap ul {
  width: 90%;
  margin: 0 auto;
}
.wrap li {
  list-style: none;
  width: 100%;
  height: 50px;
  line-height: 50px;
  border-bottom: 1px solid #ccc;
  display: flex;
  justify-content: space-between;
}
.coo {
  color: #ccc;
}
.co {
  color: #000;
}
.list-right-mar {
  margin-left: 20px;
}
</style>
